<template>
  <div class="">
    <b-row>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserCheckIcon"
          :statistic="statistics.incomes"
          color="success"
          statistic-title="Ingresos"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          :statistic="statistics.expenses"
          color="info"
          statistic-title="Egresos"
        />
      </b-col>
      <b-col
        lg="4"
        sm="6"
      >
        <statistic-card-horizontal
          icon="UserIcon"
          color="danger"
          :statistic="statistics.stockTotal"
          statistic-title="Stock total"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="12"
      >
        <b-card>
          <b-tabs
            v-if="historyData"
            pills
          >
            <!-- Tab: Quotation -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="ArrowUpIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Ingresos</span>
              </template>
              <history-view-income-card :code="codeProduct" />
            </b-tab>

            <!-- Tab: Sale -->
            <b-tab>
              <template #title>
                <feather-icon
                  icon="ArrowDownIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Egresos</span>
              </template>
              <history-view-expense-card :code="codeProduct" />
            </b-tab>

          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import productHistoryStoreModule from '@/views/dramox/product/history/productHistoryStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal'
import HistoryViewIncomeCard from '@/views/dramox/product/history/components/HistoryViewIncomeCard'
import HistoryViewExpenseCard from '@/views/dramox/product/history/components/HistoryViewExpenseCard'
import router from '@/router'

export default {
  name: 'HomeIncomeExpenses',
  components: {
    HistoryViewExpenseCard,
    HistoryViewIncomeCard,
    StatisticCardHorizontal,
    BCard,
    BRow,
    BCol,
    BTab,
    BTabs,
  },
  directives: {
    Ripple,
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-products-histories'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productHistoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const statistics = ref({
      incomes: 0,
      expenses: 0,
      stockTotal: 0,
    })
    const historyData = ref({})
    const codeProduct = ref(router.currentRoute.params.code)

    store.dispatch('app-products-histories/fetchHistoryStatistics', {
      params: { code: codeProduct.value },
    })
      .then(response => {
        const { statistics: statisticData } = response.data.payload.results
        statistics.value = statisticData
      }).catch(e => {
        console.log(e)
      })

    return {
      statistics,
      historyData,
      codeProduct,
    }
  },
}
</script>

<style scoped>

</style>
