<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <h3>Egresos</h3>
        </b-col>
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              variant="primary"
              @click="downloadHistories"
            >
              Excel
            </b-button>
          </div>

        </b-col>
      </b-row>
    </div>
    <b-table
      striped
      responsive
      :fields="tableColumns"
      :items="fetchHistories"
      class=""
    >

      <!-- Column: Quotation code -->
      <template #cell(sale_code)="data">
        <b-media vertical-align="center">
          <b-link
            :to="{ name: 'sales-show', params: { id: data.item.id }}"
            class="alert-link font-weight-bold d-block text-nowrap"
          >
            {{ data.item.sale_code }}
          </b-link>
        </b-media>
      </template>

      <!-- Column: Subtotal -->
      <template #cell(total)="data">
        <span class="text-nowrap">
          {{ data.item.currency_symbol }} {{ data.item.total }}
        </span>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
} from 'bootstrap-vue'
import { onUnmounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import productHistoryStoreModule from '@/views/dramox/product/history/productHistoryStoreModule'
import router from '@/router'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BTooltip, BPagination, BRow, BCol, BLink, BMedia, BBadge, BButton,
  },
  props: {
    code: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const APP_STORE_MODULE_NAME = 'app-products-histories'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, productHistoryStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    const currentPage = ref(1)
    const perPage = ref(10)
    const total = ref(0)
    const refListTable = ref(null)
    const productName = ref({})


    const refetchData = () => {
      refListTable.value.refresh()
    }

    const fetchHistories = (ctx, callback) => {
      store.dispatch('app-products-histories/fetchHistoryExpenses', {
        params: {
          page: currentPage.value,
          code: props.code,
        },
      })
        .then(response => {
          const { data, total: totalData } = response.data.payload.results.expenses
          if (data.length > 0) {
            productName.value = data[0].productName
          }
          callback(data)
          total.value = totalData
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    watch([currentPage], () => {
      refetchData()
    })

    const tableColumns = [
      { key: 'sale_date', label: 'Fecha venta', sortable: false },
      { key: 'sale_code', label: 'Código de venta', sortable: false },
      { key: 'quantity', label: 'Cantidad', sortable: false },
    ]

    const openPDF = val => {
      const url = `${axios.defaults.baseURL}admin/pdf/quotation/${val.id}`
      window.open(url, '_blanck')
    }

    const downloadHistories = () => {
      store.dispatch('app-products-histories/downloadProductHistoriesExpenses', {
        params: {
          page: currentPage.value,
          code: props.code,
        },
      })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${productName.value}-historial.xlsx`)
          document.body.appendChild(link)
          link.click()
        }).catch(e => {
          console.log(e)
        })
    }

    return {
      tableColumns,
      currentPage,

      openPDF,
      fetchHistories,
      perPage,
      total,
      downloadHistories,
    }
  },
}
</script>

<style>

</style>
